import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class SpecificGiftService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;
    

    const response = await authAxios.put(
      `/tenant/${newTenantId}/specific-gift/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.delete(
      `/tenant/${newTenantId}/specific-gift`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/specific-gift`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/specific-gift/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.get(
      `/tenant/${newTenantId}/specific-gift`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAvailableAsset(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    var newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/specific-gift/asset`,
      {
        params,
      },
    );
    // console.log("from listAvailableAsset response", response)
    return response.data;
  }
}
