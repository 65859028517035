import moment, { Moment } from 'moment';

/**
 * Checks if the given value is an empty object.
 *
 * @param {any} value - The value to check.
 * @return {boolean} Returns true if the value is an empty object, otherwise false.
 */
export const isEmptyObjectOrString = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (
    typeof value === 'object' &&
    Object.keys(value).length === 0
  ) {
    return true;
  }

  if (
    Object.prototype.toString.call(value) ===
    '[object Object]'
  ) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  return false;
};

/**
 * Trim trailing whitespace from an address, and append a comma if one
 * doesn't already exist at the end.
 * @param {string} address
 * @return {string}
 */
export const trimAddress = (address: string): string => {
  const trimmedAddress = address?.trimEnd() ?? '';

  // Append comma if it doesn't exist at the end
  if (
    !trimmedAddress.endsWith(',') &&
    trimmedAddress.length > 0
  ) {
    return trimmedAddress + ',';
  }

  return trimmedAddress;
};

export const formatAddress = (
  addresses: string[],
): string => {
  var new_addresses = addresses
    .filter(
      (o) => o !== '' && o !== null && o !== undefined,
    )
    .map((o, ind) => {
      var removeBreakAddress = o
        .split('\n')
        .filter(
          (obj) =>
            obj !== '' && obj !== null && obj !== undefined,
        )
        .map((obj) => {
          var address = obj.trimEnd();

          if (!address.endsWith(',')) {
            address = address + ', ';
          } else {
            address = address + ' ';
          }

          return address;
        })
        .join('');

      var address = removeBreakAddress.trimEnd();

      if (!address.endsWith(',')) {
        address = address + ', ';
      } else {
        address = address + ' ';
      }

      return address;
    });

  var new_address = new_addresses.join('').trimEnd() || '';

  if (new_address.endsWith(',')) {
    new_address = new_address.slice(0, -1);
  }

  return new_address || '';
};

/**
 * Format a given number as a currency value, with the appropriate
 * number of decimal places and thousands separators.
 *
 * @param {number|string} value - The number to be formatted
 * @return {string} The formatted currency value
 * @throws {Error} If the value is not a number
 */
export const formatCurrencyValue = (
  value: number | string,
): string => {
  try {
    // console.log('formatCurrencyValue: %s', value);
    if (value === null || value === undefined) {
      return '';
    }
    let sourceVal =
      typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(sourceVal)) {
      throw new Error(`Invalid value: ${value}`);
    }
    return sourceVal
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } catch (e) {
    console.error(
      'formatCurrencyValue Err: %s %o',
      e,
      value,
    );
    return '-999999999.99';
  }
};
/**
 * Checks if an expired or unpurchased plan exists for a given Client.
 *
 * This function checks the plan status and expiration dates for various services
 * such as custodian, executor, and testamentary services. It also checks for
 * unpurchased plans and returns true if any of these conditions are met.
 *
 * @param {object} item - The Client's Tenant object to check for expired or unpurchased plans.
 * @return {boolean} True if an expired or unpurchased plan exists, false otherwise.
 */
export const checkIfExpiredOrUnpurchasedPlanExist = (
  item,
) => {
  let result = false;
  let willMode = item.userSettingsWillMode ?? 'will';

  if (
    item.plan === 'free' ||
    (item.planExpiresDate &&
      moment().isAfter(moment(item.planExpiresDate)))
  ) {
    return true;
  }
  if (item?.Selected_Trustee) {
    switch (item?.Selected_Trustee?.toUpperCase()) {
      case 'PALLADIUM': {
        if (
          !item.Paid_Custodian_Palladium ||
          (item.Custodian_Palladium_ExpiryDate &&
            moment().isAfter(
              moment(item.Custodian_Palladium_ExpiryDate),
            )) ||
          !item.Paid_Executor_Palladium ||
          (item.Executor_Palladium_ExpiryDate &&
            moment().isAfter(
              moment(item.Executor_Palladium_ExpiryDate),
            )) ||
          !item.Paid_Testamentary_Palladium ||
          (item.Testamentary_Palladium_ExpiryDate &&
            moment().isAfter(
              moment(
                item.Testamentary_Palladium_ExpiryDate,
              ),
            ))
        ) {
          result = true;
        }

        break;
      }
      case 'MYPREMIER': {
        if (
          !item.Paid_Custodian_MyPremier ||
          (item.Custodian_MyPremier_ExpiryDate &&
            moment().isAfter(
              moment(item.Custodian_MyPremier_ExpiryDate),
            )) ||
          !item.Paid_Executor_MyPremier ||
          (item.Executor_MyPremier_ExpiryDate &&
            moment().isAfter(
              moment(item.Executor_MyPremier_ExpiryDate),
            )) ||
          !item.Paid_Testamentary_MyPremier ||
          (item.Testamentary_MyPremier_ExpiryDate &&
            moment().isAfter(
              moment(
                item.Testamentary_MyPremier_ExpiryDate,
              ),
            ))
        ) {
          result = true;
        }
        break;
      }
      default: {
        result = true;
        break;
      }
    }
  }
  if (willMode === 'wasiat') {
    if (!item?.Paid_Hibah) {
      result = true;
    }
    if (!item?.Paid_HartaSepencarian) {
      result = true;
    }
    if (!item?.Paid_HibahRevocation) {
      result = true;
    }
  }
  return result;
};

export const shortenTextToNLetters = (
  text: string,
  n: number,
) => {
  if (!text) {
    return '';
  }
  if (text?.length <= n) {
    return text;
  }
  return `${text?.substring(0, n)}...`;
};

export const convertEmbedUrlToWatchUrl = (
  embedUrl: string,
): string => {
  const embedPattern =
    /https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;

  if (!embedPattern.test(embedUrl)) {
    return embedUrl;
  }
  const match = embedUrl.match(embedPattern);

  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/watch?v=${videoId}`;
  } else {
    throw new Error('Invalid YouTube embed URL');
  }
};

export const convertVimeoUrlToEmbedLink = (
  vimeoUrl: string,
): string | null => {
  const regex =
    /^(https?:\/\/)?(www\.)?vimeo\.com\/(channels\/[\w]+\/|groups\/[\w]+\/videos\/|album\/\d+\/video\/|ondemand\/[\w]+\/|[\w\-]+\/[\w\-]+|[\d]+)(\?[^\s]*)?$/;
  const match = vimeoUrl.match(regex);

  if (match && match[1]) {
    const videoId = match[3];
    return `https://player.vimeo.com/video/${videoId}`;
  }

  return null; // Return null if the URL is not a valid Vimeo URL
};

export const convertYoutubeLinkToEmbedUrl = (
  watchUrl: string,
): string => {
  const watchPattern =
    /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
  if (!watchPattern.test(watchUrl)) {
    return watchUrl;
  }
  const match = watchUrl.match(watchPattern);

  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    throw new Error('Invalid YouTube watch URL');
  }
};

export const convertGoogleDriveUrlToEmbedLink = (
  driveUrl: string,
): string | null => {
  const regex =
    /^(https?:\/\/)?(www\.)?drive\.google\.com\/(file\/d\/[\w\-]+\/?|open\?id=[\w\-]+|uc\?id=[\w\-]+)&?/;

  const match = driveUrl.match(regex);

  if (match && match[3]) {
    const fileId = match[3];
    return `https://drive.google.com/${fileId}preview`;
  }

  return null; // Return null if the URL is not a valid Google Drive video URL
};

export type TMediaType = {
  extension: string;
  download: boolean;
  embed: boolean;
  orgExtension: string;
  customEmbedLink: string;
  downloadUrl: string;
  thumbnail:
    | 'pdf'
    | 'doc'
    | 'csv'
    | 'img'
    | 'mov'
    | 'xls'
    | 'zip';
};

export const checkExternalLink = (
  url: string,
): string | undefined => {
  const drivePattern =
    /^(https?:\/\/)?(www\.)?drive\.google\.com\/(file\/d\/[\w\-]+\/?|open\?id=[\w\-]+|uc\?id=[\w\-]+)&?/;
  const youtubePattern =
    /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const vimeoPattern =
    /^(https?:\/\/)?(www\.)?vimeo\.com\/(channels\/[\w]+\/|groups\/[\w]+\/videos\/|album\/\d+\/video\/|ondemand\/[\w]+\/|[\w\-]+\/[\w\-]+|[\d]+)(\?[^\s]*)?$/;

  if (youtubePattern.test(url)) {
    return convertYoutubeLinkToEmbedUrl(url);
  }

  if (vimeoPattern.test(url)) {
    return convertVimeoUrlToEmbedLink(url);
  }

  if (drivePattern.test(url)) {
    return convertGoogleDriveUrlToEmbedLink(url);
  }

  return undefined;
};

const getExtension = (url: string): string | undefined => {
  const urlObj = new URL(url);
  const pathSegments = urlObj.pathname.split('/');
  const lastSegment = pathSegments.pop();
  return lastSegment?.split('.').pop()?.toLowerCase();
};

export const checkMediaType = (
  url: string,
  downloadUrl: string,
  isExternal: boolean,
): TMediaType => {
  const pdfExtensions = ['pdf'];
  // Get the extension from the URL or file name
  const extension = getExtension(
    isExternal ? url : `http://tmp/${url}`,
  );

  // Files needs to be in embed format and render in iframe
  if (isExternal) {
    const embedLink = checkExternalLink(url);

    if (embedLink) {
      return {
        extension: 'embed',
        orgExtension: undefined,
        embed: true,
        download: false,
        customEmbedLink: embedLink,
        downloadUrl,
        thumbnail: 'mov',
      };
    }

    if (pdfExtensions.includes(extension)) {
      return {
        extension: 'pdf',
        orgExtension: extension,
        embed: true,
        download: true,
        customEmbedLink: undefined,
        downloadUrl: downloadUrl ?? url,
        thumbnail: 'pdf',
      };
    }
  }

  // Define known file type extensions
  const videoExtensions = [
    'mp4',
    'mkv',
    'avi',
    'mov',
    'wmv',
    'flv',
  ];
  const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'svg',
    'webp',
  ];
  const excelExtensions = ['xls', 'xlsx', 'xlsm'];
  const wordExtensions = ['doc', 'docx'];

  // Check file type by extension
  if (extension) {
    if (videoExtensions.includes(extension)) {
      return {
        extension: 'mov',
        orgExtension: extension,
        download: true,
        embed: false,
        customEmbedLink: undefined,
        downloadUrl: downloadUrl ?? url,
        thumbnail: 'mov',
      };
    } else if (imageExtensions.includes(extension)) {
      return {
        extension: 'img',
        orgExtension: extension,
        download: true,
        embed: false,
        customEmbedLink: undefined,
        downloadUrl: downloadUrl ?? url,
        thumbnail: 'img',
      };
    } else if (pdfExtensions.includes(extension)) {
      return {
        extension: 'doc',
        orgExtension: extension,
        download: true,
        embed: true,
        customEmbedLink: undefined,
        downloadUrl: downloadUrl ?? url,
        thumbnail: 'pdf',
      };
    } else if (excelExtensions.includes(extension)) {
      return {
        extension: 'xls',
        orgExtension: extension,
        download: true,
        embed: true,
        customEmbedLink: undefined,
        downloadUrl: downloadUrl ?? url,
        thumbnail: 'xls',
      };
    } else if (wordExtensions.includes(extension)) {
      return {
        extension: 'doc',
        orgExtension: extension,
        download: true,
        embed: true,
        customEmbedLink: undefined,
        downloadUrl: downloadUrl ?? url,
        thumbnail: 'doc',
      };
    }
  }

  return undefined;
};

export const copyToClipboard = (
  payload: string,
  callback?: () => any,
) => {
  navigator.clipboard.writeText(
    convertEmbedUrlToWatchUrl(payload),
  );
  callback?.();
};

export const sizeInBytesToString = (
  size: number,
): string => {
  const ONE_KB = 1024;
  const ONE_MB = 1024 * 1024;
  const ONE_GB = 1024 * 1024 * 1024;

  if (size < ONE_KB) {
    return `${size} Bytes`;
  } else if (size < ONE_MB) {
    const sizeInKB = size / ONE_KB;
    return `${sizeInKB.toFixed(2)} KB`;
  } else if (size < ONE_GB) {
    const sizeInMB = size / ONE_MB;
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    const sizeInGB = size / ONE_GB;
    return `${sizeInGB.toFixed(2)} GB`;
  }
};

export const validateEmail = (email: string): boolean => {
  const validEmailFormat =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return validEmailFormat.test(String(email).toLowerCase());
};

export const timeSince = (date: string | Date): string => {
  const givenDate = moment(date);
  const now = moment();

  const daysDifference = now.diff(givenDate, 'days');
  const weeksDifference = now.diff(givenDate, 'weeks');
  const monthsDifference = now.diff(givenDate, 'months');

  if (daysDifference < 7) {
    return daysDifference === 0
      ? 'Today'
      : `${daysDifference} day${
          daysDifference !== 1 ? 's' : ''
        } ago`;
  } else if (weeksDifference < 4) {
    return `${weeksDifference} week${
      weeksDifference !== 1 ? 's' : ''
    } ago`;
  } else {
    return `${monthsDifference} month${
      monthsDifference !== 1 ? 's' : ''
    } ago`;
  }
};

export const formatDate = (
  payload: string | Date,
): string => {
  const date: Moment = moment(payload);

  // Format the date to 'yyyy-mm-dd hh:mm'
  return date.format('YYYY-MM-DD HH:mm');
};

export const toFirstLetterUppercase = (
  input: string,
): string => {
  return input
    .split(/(?=[A-Z])|[_\s]/)
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};

export const getBase64 = (
  img: any,
  callback: (url: string) => void,
) => {
  const reader = new FileReader();
  reader.addEventListener('load', () =>
    callback(reader.result as string),
  );
  reader.readAsDataURL(img);
};

export const toKebabCase = (payload: string) => {
  return payload
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with one
    .replace(/^-|-$/g, ''); // Remove leading or trailing hyphens
};

export const generateUserName = (
  fullName: string,
  id: string,
): string | undefined => {
  return fullName &&
    fullName.length > 0 &&
    id &&
    id.length > 3
    ? `${toKebabCase(fullName)}-${id.slice(-3)}`
    : undefined;
};

const phoneNumberRegex = /^\+?\d{10,15}$/;

export const isPhoneNumber = (payload: string | number) =>
  phoneNumberRegex.test(`${payload}`);

export const isValidUsername = (input: string): boolean => {
  const regex = /^[a-zA-Z]+(-[a-zA-Z0-9]+)*$/;
  return regex.test(input);
};
