import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import exporterFields from 'src/modules/user/list/userListExporterFields';
import customerExporterFields from 'src/modules/user/list/userListCustomerExporterFields';
import selectors from 'src/modules/user/list/userListSelectors';
import UserService from 'src/modules/user/userService';

const prefix = 'USER_LIST';

const userListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
  DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
  DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  doChangeSelected(payload) {
    return {
      type: userListActions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: userListActions.RESETED,
    });

    dispatch(userListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: userListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await UserService.fetchUsers(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );

      new Exporter(
        exporterFields,
        i18n('user.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: userListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: userListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePaginationAndSort:
    (pagination, sorter, advisor_code) =>
      async (dispatch, getState) => {
        dispatch({
          type: userListActions.PAGINATION_CHANGED,
          payload: pagination,
        });

        dispatch({
          type: userListActions.SORTER_CHANGED,
          payload: sorter,
        });

        dispatch(
          userListActions.doFetchCurrentFilter(advisor_code),
        );
      },

  doFetchCurrentFilter:
    (advisor_code) => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        userListActions.doFetch(
          filter,
          rawFilter,
          advisor_code,
          true,
        ),
      );
    },

  doFetch:
    (
      filter?,
      rawFilter?,
      advisor_code?,
      keepPagination = false,
    ) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: userListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          });

          // const currentUser = await service.fetchMe();

          const referralCode = advisor_code;

          const response = await UserService.fetchAllUser(
            { ...filter, referralCode: referralCode },
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          );

          dispatch({
            type: userListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: userListActions.FETCH_ERROR,
          });
        }
      },

  doChangePaginationAndSortFilter:
    (pagination, sorter, advisor_code) =>
      async (dispatch, getState) => {
        dispatch({
          type: userListActions.PAGINATION_CHANGED,
          payload: pagination,
        });

        dispatch({
          type: userListActions.SORTER_CHANGED,
          payload: sorter,
        });

        dispatch(
          userListActions.doFetchCurrentUserFilter(
            advisor_code,
          ),
        );
      },

  doChangePaginationAndSortFilterForTrusteeClient:
    (pagination, sorter) => async (dispatch, getState) => {
      dispatch({
        type: userListActions.PAGINATION_CHANGED,
        payload: pagination,
      });

      dispatch({
        type: userListActions.SORTER_CHANGED,
        payload: sorter,
      });

      dispatch(
        userListActions.doFetchCurrentTrusteeClientFilter(),
      );
    },

  doFetchCurrentUserFilter:
    (advisor_code) => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        userListActions.doFetchFilter(
          filter,
          rawFilter,
          advisor_code,
          true,
        ),
      );
    },

  doFetchCurrentTrusteeClientFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        userListActions.doFetchTrusteeClientFilter(
          filter,
          rawFilter,
          true,
        ),
      );
    },

  doFetchFilter:
    (
      filter?,
      rawFilter?,
      advisor_code?,
      keepPagination = false,
    ) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: userListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          });

          // const currentUser = await service.fetchMe();

          const referralCode = advisor_code;

          const response =
            await UserService.fetchAllUserFilter(
              { ...filter, referralCode: referralCode },
              selectors.selectOrderBy(getState()),
              selectors.selectLimit(getState()),
              selectors.selectOffset(getState()),
            );

          dispatch({
            type: userListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: userListActions.FETCH_ERROR,
          });
        }
      },

  doFetchTrusteeClientFilter:
    (filter?, rawFilter?, keepPagination = false) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: userListActions.FETCH_STARTED,
            payload: { filter, rawFilter, keepPagination },
          });

          // const currentUser = await service.fetchMe();

          const response =
            await UserService.fetchAllTrusteeClientFilter(
              filter,
              selectors.selectOrderBy(getState()),
              selectors.selectLimit(getState()),
              selectors.selectOffset(getState()),
            );

          dispatch({
            type: userListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: userListActions.FETCH_ERROR,
          });
        }
      },

  doDestroy:
    (id, advisor_code, openSnackbar) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: userListActions.DESTROY_STARTED,
          });

          await UserService.destroy([id]);

          dispatch({
            type: userListActions.DESTROY_SUCCESS,
          });

          openSnackbar(
            i18n('user.doDestroySuccess'),
            'success',
          );
          dispatch(
            userListActions.doFetchCurrentFilter(
              advisor_code,
            ),
          );
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: userListActions.DESTROY_ERROR,
          });

          dispatch(
            userListActions.doFetchCurrentFilter(
              advisor_code,
            ),
          );
        }
      },

  doDestroyAllSelected:
    (advisor_code, openSnackbar) =>
      async (dispatch, getState) => {
        try {
          const selectedRows = selectors.selectSelectedRows(
            getState(),
          );

          dispatch({
            type: userListActions.DESTROY_ALL_SELECTED_STARTED,
          });

          await UserService.destroy(
            selectedRows.map((row) => row.id),
          );

          dispatch({
            type: userListActions.DESTROY_ALL_SELECTED_SUCCESS,
          });

          openSnackbar(
            i18n('user.doDestroyAllSelectedSuccess'),
            'success',
          );

          dispatch(
            userListActions.doFetchCurrentFilter(
              advisor_code,
            ),
          );
        } catch (error) {
          Errors.handle(error);

          dispatch({
            type: userListActions.DESTROY_ALL_SELECTED_ERROR,
          });

          dispatch(
            userListActions.doFetchCurrentFilter(
              advisor_code,
            ),
          );
        }
      },

  doExportClient: (data) => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: userListActions.EXPORT_STARTED,
      });

      new Exporter(
        customerExporterFields,
        i18n('user.exporterFileName'),
      ).transformAndExportAsExcelFile(data);

      dispatch({
        type: userListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: userListActions.EXPORT_ERROR,
      });
    }
  },
};

export default userListActions;
