import { ConnectedRouter } from 'connected-react-router';
import {
  configureStore,
  getHistory,
} from 'src/modules/store';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import { ConfigProvider } from 'antd';
import { getAntdLanguage } from 'src/i18n';
import {
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';

import ReactGA from 'react-ga4';
import { SnackbarProvider } from './hooks/useSnackbar';
import { useEffect, useState } from 'react';

ReactGA.initialize('G-76GHE9DLT7');
ReactGA.send('pageview');

const store = configureStore();

const THEME = createTheme({
  typography: {
    fontFamily: `"Noto Sans", sans-serif`,
    // fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const App = (props) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // Check screen size on initial load and on window resize
  const checkScreenSize = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth < 768 || screenHeight < 500) {
      // Threshold for mobile (can adjust if needed)
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    // Check the screen size on component mount
    checkScreenSize();

    // Add event listener to detect window resize
    window.addEventListener('resize', checkScreenSize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (isMobile) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          height: '100vh',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 10,
        }}
      >
        <img
          src="/illustrations/screenAlert.svg"
          alt="content not fit for mobile"
          style={{
            width: '120px',
            height: 'auto',
            marginBottom: '20px',
          }}
        />
        <h1
          style={{ textAlign: 'center', fontSize: '24px' }}
        >
          Oops, Too Small!
        </h1>
        <p
          style={{
            textAlign: 'center',
            maxWidth: '45ch',
            color: 'gray',
            fontSize: '12px',
          }}
        >
          It appears that you are using a device with a
          screen that is too small for Koha legacy planner
          interface. For the best experience, please switch
          to a tablet or desktop. Thank you!
        </p>
      </div>
    );
  }

  return (
    <MuiThemeProvider theme={THEME}>
      <ConfigProvider locale={getAntdLanguage()}>
        <Provider store={store}>
          <ConnectedRouter history={getHistory()}>
            <SnackbarProvider>
              <RoutesComponent />
            </SnackbarProvider>
          </ConnectedRouter>
        </Provider>
      </ConfigProvider>
    </MuiThemeProvider>
  );
};

export default App;
