import { i18n } from 'src/i18n';

const accountEnumerators = {
  accType: [
    'Savings',
    'Investment',
    // 'Insurance/Takaful',
    'RegularSavingFunds',
    'Others',
  ],
  accType2: [{
    label: i18n(
      'entities.account.enumerators.accType2.Savings',
    ),
    value: 'Savings',
  },{
    label: i18n(
      'entities.account.enumerators.accType2.Investment',
    ),
    value: 'Investment',
  },{
    label: i18n(
      'entities.account.enumerators.accType2.RegularSavingFunds',
    ),
    value: 'RegularSavingFunds',
  },{
    label: i18n(
      'entities.account.enumerators.accType2.Others',
    ),
    value: 'Others',
  }
  ],
};

export default accountEnumerators;
