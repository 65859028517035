export const headerHeight = 64;
export const largeScreenMaxWidth = 1200;
export const countryPhoneCodes = {
  AF: { code: '+93', name: 'Afghanistan' },
  AL: { code: '+355', name: 'Albania' },
  DZ: { code: '+213', name: 'Algeria' },
  AS: { code: '+1684', name: 'American Samoa' },
  AD: { code: '+376', name: 'Andorra' },
  AO: { code: '+244', name: 'Angola' },
  AI: { code: '+1264', name: 'Anguilla' },
  AG: { code: '+1268', name: 'Antigua and Barbuda' },
  AR: { code: '+54', name: 'Argentina' },
  AM: { code: '+374', name: 'Armenia' },
  AW: { code: '+297', name: 'Aruba' },
  AU: { code: '+61', name: 'Australia' },
  AT: { code: '+43', name: 'Austria' },
  AZ: { code: '+994', name: 'Azerbaijan' },
  BS: { code: '+1242', name: 'Bahamas' },
  BH: { code: '+973', name: 'Bahrain' },
  BD: { code: '+880', name: 'Bangladesh' },
  BB: { code: '+1246', name: 'Barbados' },
  BY: { code: '+375', name: 'Belarus' },
  BE: { code: '+32', name: 'Belgium' },
  BZ: { code: '+501', name: 'Belize' },
  BJ: { code: '+229', name: 'Benin' },
  BM: { code: '+1441', name: 'Bermuda' },
  BT: { code: '+975', name: 'Bhutan' },
  BO: { code: '+591', name: 'Bolivia' },
  BA: { code: '+387', name: 'Bosnia and Herzegovina' },
  BW: { code: '+267', name: 'Botswana' },
  BR: { code: '+55', name: 'Brazil' },
  IO: {
    code: '+246',
    name: 'British Indian Ocean Territory',
  },
  BN: { code: '+673', name: 'Brunei Darussalam' },
  BG: { code: '+359', name: 'Bulgaria' },
  BF: { code: '+226', name: 'Burkina Faso' },
  BI: { code: '+257', name: 'Burundi' },
  KH: { code: '+855', name: 'Cambodia' },
  CM: { code: '+237', name: 'Cameroon' },
  CA: { code: '+1', name: 'Canada' },
  CV: { code: '+238', name: 'Cape Verde' },
  KY: { code: '+1345', name: 'Cayman Islands' },
  CF: { code: '+236', name: 'Central African Republic' },
  TD: { code: '+235', name: 'Chad' },
  CL: { code: '+56', name: 'Chile' },
  CN: { code: '+86', name: 'China' },
  CX: { code: '+61', name: 'Christmas Island' },
  CC: { code: '+61', name: 'Cocos Islands' },
  CO: { code: '+57', name: 'Colombia' },
  KM: { code: '+269', name: 'Comoros' },
  CG: { code: '+242', name: 'Congo' },
  CD: {
    code: '+243',
    name: 'Congo, Democratic Republic of the',
  },
  CK: { code: '+682', name: 'Cook Islands' },
  CR: { code: '+506', name: 'Costa Rica' },
  CI: { code: '+225', name: "Côte d'Ivoire" },
  HR: { code: '+385', name: 'Croatia' },
  CU: { code: '+53', name: 'Cuba' },
  CW: { code: '+599', name: 'Curaçao' },
  CY: { code: '+357', name: 'Cyprus' },
  CZ: { code: '+420', name: 'Czech Republic' },
  DK: { code: '+45', name: 'Denmark' },
  DJ: { code: '+253', name: 'Djibouti' },
  DM: { code: '+1767', name: 'Dominica' },
  DO: { code: '+1809', name: 'Dominican Republic' },
  EC: { code: '+593', name: 'Ecuador' },
  EG: { code: '+20', name: 'Egypt' },
  SV: { code: '+503', name: 'El Salvador' },
  GQ: { code: '+240', name: 'Equatorial Guinea' },
  ER: { code: '+291', name: 'Eritrea' },
  EE: { code: '+372', name: 'Estonia' },
  ET: { code: '+251', name: 'Ethiopia' },
  FK: { code: '+500', name: 'Falkland Islands' },
  FO: { code: '+298', name: 'Faroe Islands' },
  FJ: { code: '+679', name: 'Fiji' },
  FI: { code: '+358', name: 'Finland' },
  FR: { code: '+33', name: 'France' },
  GF: { code: '+594', name: 'French Guiana' },
  PF: { code: '+689', name: 'French Polynesia' },
  GA: { code: '+241', name: 'Gabon' },
  GB: { code: '+44', name: 'United Kingdom' },
  GD: { code: '+1473', name: 'Grenada' },
  GE: { code: '+995', name: 'Georgia' },
  GH: { code: '+233', name: 'Ghana' },
  GI: { code: '+350', name: 'Gibraltar' },
  GR: { code: '+30', name: 'Greece' },
  GL: { code: '+299', name: 'Greenland' },
  GT: { code: '+502', name: 'Guatemala' },
  GN: { code: '+224', name: 'Guinea' },
  GW: { code: '+245', name: 'Guinea-Bissau' },
  GY: { code: '+592', name: 'Guyana' },
  HT: { code: '+509', name: 'Haiti' },
  HN: { code: '+504', name: 'Honduras' },
  HK: { code: '+852', name: 'Hong Kong' },
  HU: { code: '+36', name: 'Hungary' },
  IS: { code: '+354', name: 'Iceland' },
  IN: { code: '+91', name: 'India' },
  ID: { code: '+62', name: 'Indonesia' },
  IR: { code: '+98', name: 'Iran' },
  IQ: { code: '+964', name: 'Iraq' },
  IE: { code: '+353', name: 'Ireland' },
  IL: { code: '+972', name: 'Israel' },
  IT: { code: '+39', name: 'Italy' },
  JM: { code: '+1876', name: 'Jamaica' },
  JP: { code: '+81', name: 'Japan' },
  JO: { code: '+962', name: 'Jordan' },
  KZ: { code: '+7', name: 'Kazakhstan' },
  KE: { code: '+254', name: 'Kenya' },
  KI: { code: '+686', name: 'Kiribati' },
  KW: { code: '+965', name: 'Kuwait' },
  KG: { code: '+996', name: 'Kyrgyzstan' },
  LA: { code: '+856', name: 'Laos' },
  LV: { code: '+371', name: 'Latvia' },
  LB: { code: '+961', name: 'Lebanon' },
  LS: { code: '+266', name: 'Lesotho' },
  LR: { code: '+231', name: 'Liberia' },
  LY: { code: '+218', name: 'Libya' },
  LI: { code: '+423', name: 'Liechtenstein' },
  LT: { code: '+370', name: 'Lithuania' },
  LU: { code: '+352', name: 'Luxembourg' },
  MO: { code: '+853', name: 'Macau' },
  MK: { code: '+389', name: 'North Macedonia' },
  MG: { code: '+261', name: 'Madagascar' },
  MW: { code: '+265', name: 'Malawi' },
  MY: { code: '+60', name: 'Malaysia' },
  MV: { code: '+960', name: 'Maldives' },
  ML: { code: '+223', name: 'Mali' },
  MT: { code: '+356', name: 'Malta' },
  MH: { code: '+692', name: 'Marshall Islands' },
  MQ: { code: '+596', name: 'Martinique' },
  MR: { code: '+222', name: 'Mauritania' },
  MU: { code: '+230', name: 'Mauritius' },
  YT: { code: '+262', name: 'Mayotte' },
  MX: { code: '+52', name: 'Mexico' },
  FM: { code: '+691', name: 'Micronesia' },
  MD: { code: '+373', name: 'Moldova' },
  MC: { code: '+377', name: 'Monaco' },
  MN: { code: '+976', name: 'Mongolia' },
  ME: { code: '+382', name: 'Montenegro' },
  MS: { code: '+1664', name: 'Montserrat' },
  MA: { code: '+212', name: 'Morocco' },
  MM: { code: '+95', name: 'Myanmar' },
  NA: { code: '+264', name: 'Namibia' },
  NR: { code: '+674', name: 'Nauru' },
  NP: { code: '+977', name: 'Nepal' },
  NL: { code: '+31', name: 'Netherlands' },
  NC: { code: '+687', name: 'New Caledonia' },
  NZ: { code: '+64', name: 'New Zealand' },
  NI: { code: '+505', name: 'Nicaragua' },
  NE: { code: '+227', name: 'Niger' },
  NG: { code: '+234', name: 'Nigeria' },
  NU: { code: '+683', name: 'Niue' },
  NF: { code: '+672', name: 'Norfolk Island' },
  KP: { code: '+850', name: 'North Korea' },
  NO: { code: '+47', name: 'Norway' },
  OM: { code: '+968', name: 'Oman' },
  PK: { code: '+92', name: 'Pakistan' },
  PW: { code: '+680', name: 'Palau' },
  PA: { code: '+507', name: 'Panama' },
  PG: { code: '+675', name: 'Papua New Guinea' },
  PY: { code: '+595', name: 'Paraguay' },
  PE: { code: '+51', name: 'Peru' },
  PH: { code: '+63', name: 'Philippines' },
  PL: { code: '+48', name: 'Poland' },
  PT: { code: '+351', name: 'Portugal' },
  PR: { code: '+1', name: 'Puerto Rico' },
  QA: { code: '+974', name: 'Qatar' },
  RO: { code: '+40', name: 'Romania' },
  RU: { code: '+7', name: 'Russia' },
  RW: { code: '+250', name: 'Rwanda' },
  RE: { code: '+262', name: 'Réunion' },
  BL: { code: '+590', name: 'Saint Barthélemy' },
  SH: { code: '+290', name: 'Saint Helena' },
  KN: { code: '+1869', name: 'Saint Kitts and Nevis' },
  LC: { code: '+1758', name: 'Saint Lucia' },
  MF: { code: '+590', name: 'Saint Martin (French part)' },
  PM: { code: '+508', name: 'Saint Pierre and Miquelon' },
  VC: {
    code: '+1784',
    name: 'Saint Vincent and the Grenadines',
  },
  WS: { code: '+685', name: 'Samoa' },
  SM: { code: '+378', name: 'San Marino' },
  ST: { code: '+239', name: 'São Tomé and Príncipe' },
  SA: { code: '+966', name: 'Saudi Arabia' },
  SN: { code: '+221', name: 'Senegal' },
  RS: { code: '+381', name: 'Serbia' },
  SC: { code: '+248', name: 'Seychelles' },
  SL: { code: '+232', name: 'Sierra Leone' },
  SG: { code: '+65', name: 'Singapore' },
  SX: { code: '+721', name: 'Sint Maarten (Dutch part)' },
  SK: { code: '+421', name: 'Slovakia' },
  SI: { code: '+386', name: 'Slovenia' },
  SB: { code: '+677', name: 'Solomon Islands' },
  SO: { code: '+252', name: 'Somalia' },
  ZA: { code: '+27', name: 'South Africa' },
  KR: { code: '+82', name: 'South Korea' },
  SS: { code: '+211', name: 'South Sudan' },
  ES: { code: '+34', name: 'Spain' },
  LK: { code: '+94', name: 'Sri Lanka' },
  SD: { code: '+249', name: 'Sudan' },
  SR: { code: '+597', name: 'Suriname' },
  SJ: { code: '+47', name: 'Svalbard and Jan Mayen' },
  SE: { code: '+46', name: 'Sweden' },
  CH: { code: '+41', name: 'Switzerland' },
  SY: { code: '+963', name: 'Syria' },
  TW: { code: '+886', name: 'Taiwan' },
  TJ: { code: '+992', name: 'Tajikistan' },
  TZ: { code: '+255', name: 'Tanzania' },
  TH: { code: '+66', name: 'Thailand' },
  TL: { code: '+670', name: 'Timor-Leste' },
  TG: { code: '+228', name: 'Togo' },
  TK: { code: '+690', name: 'Tokelau' },
  TO: { code: '+676', name: 'Tonga' },
  TT: { code: '+1868', name: 'Trinidad and Tobago' },
  TN: { code: '+216', name: 'Tunisia' },
  TR: { code: '+90', name: 'Turkey' },
  TM: { code: '+993', name: 'Turkmenistan' },
  TC: { code: '+1649', name: 'Turks and Caicos Islands' },
  TV: { code: '+688', name: 'Tuvalu' },
  UG: { code: '+256', name: 'Uganda' },
  UA: { code: '+380', name: 'Ukraine' },
  AE: { code: '+971', name: 'United Arab Emirates' },
  US: { code: '+1', name: 'United States' },
  UY: { code: '+598', name: 'Uruguay' },
  UZ: { code: '+998', name: 'Uzbekistan' },
  VU: { code: '+678', name: 'Vanuatu' },
  VE: { code: '+58', name: 'Venezuela' },
  VN: { code: '+84', name: 'Vietnam' },
  WF: { code: '+681', name: 'Wallis and Futuna' },
  EH: { code: '+212', name: 'Western Sahara' },
  YE: { code: '+967', name: 'Yemen' },
  ZM: { code: '+260', name: 'Zambia' },
  ZW: { code: '+263', name: 'Zimbabwe' },
} as { [key: string]: { code: string; name: string } };
