import { notification } from 'antd';

export default class Message {
  static success(arg) {
    notification.success({
      message: arg,
      description: '',
      placement: 'bottomLeft',
      style: { zIndex: 9999 },
      duration: 100,
    });
  }

  static error(arg, placement?) {
    notification.error({
      message: arg,
      description: '',
      placement: placement ?? 'bottomLeft',
    });
  }
}
