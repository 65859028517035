import Plans from 'src/security/plans';
import Permissions from 'src/security/permissions';
// import accessListService from 'src/modules/accessList/accessListService';
import AuthCurrentTenant from './authCurrentTenant';
import moment from 'moment';

const plans = Plans.values;

export default class PermissionChecker {
  currentTenant;
  currentUser;
  assistantPermission;

  constructor(
    currentTenant,
    currentUser,
    assistantPermission?,
  ) {
    this.currentTenant = currentTenant;
    this.currentUser = currentUser;
    this.assistantPermission = assistantPermission;
  }

  get currentUserRolesIds() {
    if (!this.currentUser || !this.currentUser.tenants) {
      return [];
    }

    const tenant = this.currentUser.tenants
      .filter(
        (tenantUser) => tenantUser.status === 'active',
      )
      .find(
        (tenantUser) =>
          tenantUser.tenant.id === this.currentTenant.id,
      );

    if (!tenant) {
      return [];
    }

    return tenant.roles;
  }

  match(permission) {
    if (!permission) {
      return true;
    }

    if (!this.planMatchOneOf(permission.allowedPlans)) {
      return false;
    }

    return this.rolesMatchOneOf(permission.allowedRoles);
  }

  lockedForCurrentPlan(permission) {
    if (!permission) {
      return false;
    }

    if (!this.rolesMatchOneOf(permission.allowedRoles)) {
      return false;
    }

    return !this.planMatchOneOf(permission.allowedPlans);
  }

  rolesMatchOneOf(arg) {
    if (!this.currentUserRolesIds) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some((role) =>
        this.currentUserRolesIds.includes(role),
      );
    }

    return this.currentUserRolesIds.includes(arg);
  }

  planMatchOneOf(arg) {
    if (!this.currentTenantPlan) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some(
        (plan) => plan === this.currentTenantPlan,
      );
    }

    return arg === this.currentTenantPlan;
  }

  get currentTenantPlan() {
    if (!this.currentTenant) {
      return plans.free;
    }

    if (!this.currentTenant.plan) {
      return plans.free;
    }

    return this.currentTenant.plan;
  }

  get isEmptyTenant() {
    if (!this.isAuthenticated) {
      return true;
    }

    if (!this.currentUser.tenants) {
      return true;
    }

    return !this.currentUser.tenants.some(
      (tenant) => tenant.status === 'active',
    );
  }

  get isEmptyPermissions() {
    if (!this.isAuthenticated) {
      return true;
    }

    if (!this.currentUser.tenants) {
      return true;
    }

    const tenant = this.currentUser.tenants.find(
      (tenant) => tenant.status === 'active',
    );

    if (!tenant) {
      return true;
    }

    return !tenant.roles || !tenant.roles.length;
  }

  get isAuthenticated() {
    return (
      Boolean(this.currentUser) &&
      Boolean(this.currentUser.id)
    );
  }

  get isEmailVerified() {
    if (!this.isAuthenticated) {
      return false;
    }

    return this.currentUser.emailVerified;
  }

  async matchPlan(permission, type) {
    if (!permission) {
      return true;
    }

    // const doRequest = (currentTenantPlan) => {
    //   return new Promise(async function (resolve, reject) {

    //     await Promise.all([
    //       accessListService.listAll(null, null, null, null)
    //     ])
    //       .then(data => {
    //         var result = null
    //         if (data[0].rows.length === 0) {
    //           result = false;
    //         }
    //         else {
    //           data[0].rows.forEach((row) => {
    //             if (row.planPackage.toString().trim().toLowerCase() === currentTenantPlan.toString().trim().toLowerCase()) {
    //               return result = planMatchDB(permission, type, row);
    //             }
    //           })
    //         }
    //         resolve(result);
    //       })
    //   })
    // };

    const planMatchDB = (permission, type, row) => {
      // console.log("permission.id", permission.id)
      // console.log("type", type)
      // console.log("row", row)

      var result =
        permission.id.indexOf('No') !== -1 ? 0 : false;
      Object.keys(Permissions.values).forEach(function (
        key,
      ) {
        var access = Permissions.values[key];

        if (permission.id === access.id) {
          var data = row[key];

          if (permission.id.indexOf('No') !== -1)
            data =
              data === undefined || data === null
                ? 0
                : data;
          else
            data =
              data === undefined || data === null
                ? false
                : data;

          result = data;
        }
      });
      return result;
    };
    const result = planMatchDB(
      permission,
      type,
      AuthCurrentTenant.getRemotePermission(),
    );

    return result;

    // var result = await doRequest(this.currentTenantPlan)
    // return result;

    // return this.rolesMatchOneOf(permission.allowedRoles);
  }

  matchCustomer(permission, isExtend3Month) {
    if (!permission) return true;

    const startOfToday = moment().startOf('day');
    const {
      Advisory_Comprehensive_ExpiryDate:
        comprehensiveExpiryDate,
      Advisory_Islamic_ExpiryDate: islamicExpiryDate,
      Paid_Advisory_Comprehensive: hasPaidComprehensive,
      Paid_Advisory_Islamic: hasPaidIslamic,
    } = this.currentTenant;

    // Helper function to check expiry safely
    const isExpired = (expiryDate) =>
      expiryDate
        ? moment(expiryDate).isBefore(startOfToday)
        : false;

    const isComprehensiveExpired =
      hasPaidComprehensive &&
      isExpired(comprehensiveExpiryDate);
    const isIslamicExpired =
      hasPaidIslamic && isExpired(islamicExpiryDate);

    // console.log("Comprehensive Expired:", isComprehensiveExpired);
    // console.log("Islamic Expired:", isIslamicExpired);

    if (isExtend3Month) {
      // If both are expired, check their extended validity
      if (isComprehensiveExpired && isIslamicExpired) {
        const isStillValid = (expiryDate) =>
          expiryDate
            ? moment().isBefore(
                moment(expiryDate).add(3, 'months'),
              )
            : false;

        const isComprehensiveStillValid = isStillValid(
          comprehensiveExpiryDate,
        );
        const isIslamicStillValid = isStillValid(
          islamicExpiryDate,
        );

        // console.log("Comprehensive Still Valid:", isComprehensiveStillValid);
        // console.log("Islamic Still Valid:", isIslamicStillValid);

        return (
          isComprehensiveStillValid || isIslamicStillValid
        );
      }
    } else {
      return !isComprehensiveExpired || !isIslamicExpired;
    }

    return true;
  }
}
