import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class UserService {
  static async edit(data) {
    const body = {
      data,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/user`,
      body,
    );

    return response.data;
  }

  static async Update_Advisor_Rating(data) {
    const body = {
      data,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/Update_Advisor_Rating`,
      body,
    );

    return response.data;
  }

  static async Update_Trustee_Client(data) {
    const body = {
      data,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/Update_Trustee_Client`,
      body,
    );

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/user`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: {
        ...values,
      },
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/user/${id}`,
    );
    return response.data;
  }

  static async findRemote(id) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/user/${id}`,
    );
    return response.data;
  }

  static async findFullName(id) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/user/${id}`,
    );
    // console.log(
    //   'responseform Findfullname: ',
    //   response.data,
    // );
    return response.data.fullName;
  }

  static async findFullNameDirect(id) {
    // const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(`/user/${id}`);
    return response.data.fullName;
  }

  static async findByIC(ic) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/user/IC/${ic}`,
    );
    return response.data;
  }

  static async findByEmail(email) {
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/user/email/${email}`,
    );
    return response.data;
  }

  static async findByTag(myTag) {
    // const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/user/myTag/${myTag}`,
    );
    return response.data;
  }

  static async updateWillCompleted(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    let newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.put(
      `/tenant/${newTenantId}/user/updateWillCompleted`,
      body,
    );

    return response.data;
  }

  static async fetchAllUser(
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/full_user`,
      {
        params,
      },
    );

    return response.data;
  }

  static async downloadUserDocument(section, data) {
    const body = {
      data,
      section,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/will/downloadWill`,
      body,
    );

    return response.data;
  }

  static async fetchAllUserFilter(
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/full_user_filter`,
      {
        params,
      },
    );
    return response.data;
  }

  static async fetchAllTrusteeClientFilter(
    filter: {
      clientName?: string;
      status?: string;
      willReferenceNo?: string;
    },
    orderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/user_trustee_client_filter`,
      {
        params,
      },
    );
    return response.data;
  }

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/user`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/user/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }

  static async updateLatestCustodyByUserId(id, value) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user/custody/${id}`,
      {
        value,
      },
    );

    return response.data;
  }

  static async validateWillStatus(userId) {
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    let newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/will/validation`,
      {
        userId,
      },
    );

    return response.data;
  }

  static async validateHibahStatus(newTenantId, newUserId) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/hibah/validation`,
      {
        params,
      },
    );

    return response.data;
  }

  static async validateHartaSepencarianStatus(
    newTenantId,
    newUserId,
    spouseId,
  ) {
    const params = {
      tenantId: newTenantId,
      userId: newUserId,
      spouseId: spouseId,
    };

    const response = await authAxios.get(
      `/tenant/${newTenantId}/hartaSepencarian/validation`,
      {
        params,
      },
    );

    return response.data;
  }

  static async userDeductTokenAndUpgradePlan(
    id,
    token_type,
    plans,
    clientTenantId,
    clientUserId,
  ) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/user_Deduct_Token_And_Upgrade_Plan`,
      {
        id,
        token_type,
        plans,
        clientTenantId,
        clientUserId,
      },
    );

    return response.data;
  }

  static async userFindTokenBalance(userId) {
    const tenantId = AuthCurrentTenant.get();
    const remote = await AuthCurrentTenant.getRemote();

    let newTenantId = remote?.isRemote
      ? remote.remoteTenant?._id
      : tenantId;

    const response = await authAxios.post(
      `/tenant/${newTenantId}/user/${userId}/tokenbalance`,
    );

    return response.data;
  }

  static async Handle_Trustee_Client_Generate_Will(data) {
    const body = {
      data,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/Handle_Trustee_Client_Generate_Will`,
      body,
    );

    return response.data;
  }

  static async fetchAllNonClientUser(
    filter,
    orderBy,
    limit,
    offset,
  ) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/full_nonclient_filter`,
      {
        params,
      },
    );
    return response.data;
  }

  static async inviteNonClientUser(data) {
    const body = {
      data,
    };
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/Invite_NonClientUser`,
      body,
    );

    return response.data;
  }
}
