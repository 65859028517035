import { PrintOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { memo, useRef } from 'react';
import { i18n } from 'src/i18n';
import backPage from './cardBackground/backPage.jpg';
import frontPage from './cardBackground/largeFrontPage.jpg';

// Dimensions and margins for the business card
const mmToPt = (mm: number) => (mm * 72) / 25.4; // Conversion function from mm to points
const rowCardWidth = 89.7; // Card width in points
const rowCardHeight = 56; // Card height in points
const cardWidth = mmToPt(rowCardWidth); // Card width in points
const cardHeight = mmToPt(rowCardHeight); // Card height in points
const marginX = mmToPt(10);
const marginY = mmToPt(10);
const spacingX = mmToPt(10); // Space between cards horizontally
const spacingY = mmToPt(10); // Space between cards vertically
const rowFirstParagraphMarginTop = 15; // Paragraph margin top in points
const firstParagraphMarginTop = mmToPt(
  rowFirstParagraphMarginTop,
); // Paragraph margin top in points
const rowParagraphMarginLeft = 8; // Paragraph margin left in points
const paragraphMarginLeft = mmToPt(rowParagraphMarginLeft); // Paragraph margin left in points

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getBase64ImageFromURL = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx?.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/jpeg');
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
};

const cardBgStyle = {
  fontFamily: '"MyCustomFont", sans-serif',
  border: '0.5px dashed gray',
  width: `${rowCardWidth}mm`,
  height: `${rowCardHeight}mm`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
};

interface ICustodyCard {
  numCopies?: number;
  idNumber: string;
  serialCode: string;
  name: string;
}

const CustodyCard = ({
  numCopies = 1,
  idNumber,
  serialCode,
  name,
}: ICustodyCard) => {
  const pdfRef = useRef<HTMLDivElement>(null);

  const generatePDF = async () => {
    const frontPageBase64 = await getBase64ImageFromURL(
      frontPage,
    );
    const backPageBase64 = await getBase64ImageFromURL(
      backPage,
    );

    const pageWidth = mmToPt(210); // A4 page width in points
    const pageHeight = mmToPt(297); // A4 page height in points

    // Calculate number of columns and rows per page
    const columnsPerPage = Math.floor(
      (pageWidth - 2 * marginX + spacingX) /
        (cardWidth + spacingX),
    );
    const rowsPerPage = Math.floor(
      (pageHeight - 2 * marginY + spacingY) /
        (cardHeight + spacingY),
    );

    const generateCardImages = (
      numCopies: number,
      isBack: boolean,
    ) => {
      const content = [];
      for (let i = 0; i < numCopies; i++) {
        const colIndex = i % columnsPerPage;
        const rowIndex = Math.floor(
          (i % (columnsPerPage * rowsPerPage)) /
            columnsPerPage,
        );

        const x =
          marginX + colIndex * (cardWidth + spacingX);
        const y =
          marginY + rowIndex * (cardHeight + spacingY);

        if (!isBack) {
          content.push({
            image: frontPageBase64,
            width: cardWidth,
            height: cardHeight,
            absolutePosition: { x, y },
          });
          content.push({
            columns: [
              {
                text: [
                  { text: 'Name: ', bold: true }, // Make 'Name:' bold
                  name ?? '-', // Normal text
                ],
                fontSize: 8,
                width: cardWidth / 1.5, // Set max width for wrapping
                margin: [
                  paragraphMarginLeft,
                  firstParagraphMarginTop,
                  0,
                  0,
                ], // Position left,top,right,bottom
              },
            ],
          });
          content.push({
            columns: [
              {
                text: [
                  { text: 'ID No: ', bold: true },
                  idNumber ?? '-',
                ],
                fontSize: 8,
                width: cardWidth / 1.5, // Set max width for wrapping
                margin: [paragraphMarginLeft, 8, 0, 0], // Position
              },
            ],
          });
          content.push({
            columns: [
              {
                text: [
                  { text: 'S/N: ', bold: true },
                  serialCode ?? '-',
                ],
                fontSize: 8,
                width: cardWidth / 1.5, // Set max width for wrapping
                margin: [paragraphMarginLeft, 8, 0, 0], // Position
              },
            ],
          });
        } else {
          content.push({
            image: backPageBase64,
            width: cardWidth,
            height: cardHeight,
            absolutePosition: { x, y },
          });
        }

        if (
          i > 0 &&
          i % (columnsPerPage * rowsPerPage) === 0
        ) {
          content.push({ text: '', pageBreak: 'after' });
        }
      }
      return content;
    };

    const docDefinition: any = {
      pageSize: 'A4',
      pageMargins: [marginX, marginY, marginX, marginY],
      content: [
        ...generateCardImages(numCopies, false),
        { text: '', pageBreak: 'after' },
        ...generateCardImages(numCopies, true),
      ],
    };

    pdfMake.createPdf(docDefinition).download('Card.pdf');
  };

  return (
    <div>
      <div
        ref={pdfRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        <div
          className="business-card business-card-front"
          style={{
            ...cardBgStyle,
            backgroundImage: `url(${frontPage})`,
          }}
        >
          <p
            style={{
              marginTop: `${
                firstParagraphMarginTop * 1.4
              }px`,
              marginLeft: `${paragraphMarginLeft * 1.3}px`,
              marginBottom: '0px',
              color: '#000',
              fontSize: 12,
            }}
          >
            Name: {name ?? '-'}
          </p>

          <p
            style={{
              marginTop: `5px`,
              marginLeft: `${paragraphMarginLeft * 1.3}px`,
              marginBottom: '0px',
              color: '#000',
              fontSize: 12,
            }}
          >
            ID No: {idNumber ?? '-'}
          </p>
          <p
            style={{
              marginLeft: `${paragraphMarginLeft * 1.3}px`,
              marginTop: '5px',
              color: '#000',
              fontSize: 12,
            }}
          >
            <div className="guid-container">
              S/N:{' '}
              {serialCode ? (
                <>
                  <span>
                    {serialCode?.substring(0, 12)}
                  </span>
                  <br />
                  <span>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    {serialCode?.substring(12)}
                  </span>
                </>
              ) : (
                '-'
              )}
            </div>
          </p>
        </div>
        <div
          className="business-card business-card-back"
          style={{
            ...cardBgStyle,
            backgroundImage: `url(${backPage})`,
          }}
        ></div>
      </div>
      <Button
        variant="outlined"
        endIcon={<PrintOutlined />}
        onClick={generatePDF}
        sx={{ mt: 2 }}
      >
        {i18n('common.saveCopies')}
      </Button>
    </div>
  );
};

export default memo(CustodyCard);
