import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'fullName',
    label: i18n('user.fields.fullName'),
  },
  {
    name: 'idType',
    label: i18n('user.fields.idType'),
  },
  {
    name: 'idNo',
    label: i18n('user.fields.idNo'),
  },
  {
    name: 'gender',
    label: i18n('user.fields.gender'),
  },
  {
    name: 'dob',
    label: i18n('user.fields.dob'),
  },
  {
    name: 'phoneNumber',
    label: i18n('user.fields.phoneNumber'),
  },
  {
    name: 'email',
    label: i18n('user.fields.email'),
  },
  {
    name: 'address',
    label: i18n('user.fields.address'),
    render: exporterRenders.address(),
  },
  {
    name: 'maritalStatus',
    label: i18n('user.fields.maritalStatus'),
  },
];
